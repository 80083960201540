<template>
  <div class="container">
    <div class="content">
      <div class="title">{{ data.title }}</div>
      <div class="des">发布时间：{{ data.createTime }}</div>
      <!-- <img class="contentimg" src="../../assets/ls/lsbg.png" alt="" /> -->
      <!-- <div class="bgimg" style="background-image;: url(~@/assets/ls/lsbg.png)"></div> -->
      <!--<div class="contentwz">
        春节假期将至，全国各地迎来返乡潮，医疗资源相对薄弱的乡村地区新冠感染防治面临较大压力。1月16日，人民日报新媒体联合堆云健康发起“乡村防疫助力行动”，推出7×24小时新冠义诊平台、重症远程会诊通道、新冠防控知识普及视频等，为各地基层尤其是乡村地区防疫提供专业的在线医疗健康服务支持。
      </div> -->

      <!-- 1 -->
      <div class="contentwz" v-if="id === 1">
        <div>
          &emsp;&emsp;随着互联网医疗技术的发展，越来越多的用户开始选择线上平台进行治疗。其中，在毛发健康领域，堆云健康就是一家一站式毛发医学管理解决方案和服务商，它致力于通过技术创新和优质服务为广大脱发患者提供解决方案。<br />
        </div>
        <div>
          &emsp;&emsp;为了更好地服务医生及患者，提高医生的工作效率，解决患者的真正需求，堆云健康已研发上线自己的公众号，并已陆续开展与各大医院医生联手。
        </div>
        <div class="bgimg bgimg11" />
        <div>
          一、堆云健康公众号带给医生的核心价值<br />1、医生可根据自己的专业知识，为患者提供更多的皮肤健康知识，增加医生的影响力，知名度。<br />2、通过堆云健康公众号，可以相互学习，提升自己的专业能力，从而获得更多的患者认可和信任。<br />3、通过堆云健康公众号可增加医生的阳光收入。<br />
        </div>
        <div class="bgimg bgimg12 mobile" />
        <div>
          二、堆云健康公众号带给患者的核心价值<br />1、患者在线咨询、问诊<br />患者可通过堆云健康公众号向在线医生进行咨询，在线医生会根据用户的实际情况，对患者进行初步评估，并给出合理建议及诊疗方案。<br />2、线上购药<br />堆云健康与国内知名制药厂合作，推出“防脱+养护+药物+植发+假发”全病程服务，可满足不同患者的需求，帮助不同问题的脱发患者得到有效的解决方案。<br />
        </div>
        <div class="bgimg bgimg13" />
        <div>
          堆云健康“互联网+医疗”的创新模式，让患者更加便捷地享受到优质医疗，也通过微信公众号的患者管理和信息推送等功能提高了医生工作效率。未来堆云健康将继续致力于提供优质的医疗服务和医学管理方案，助力脱发患者从根本上解决问题。
        </div>
        <div class="bgimg bgimg0" />
      </div>

      <!-- 2 -->
      <div class="contentwz" v-if="id === 2">
        <h3>项目简介</h3>
        <div>
          &emsp;&emsp;堆云是一家以互联网医疗为核心的移动医疗平台，旨在为用户提供在线问诊、处方、药品配送及健康管理等服务。预计2023年堆云将与国内
          1000多位医生合作，为超过40万患者提供优质的医疗服务。
        </div>
        <div>
          &emsp;&emsp;堆云依托移动互联网医疗平台，充分利用互联网技术和创新思维，积极探索“医、药、患”全流程就医服务模式。通过技术赋能、资源整合，搭建了集防脱,养护，药物、植发和假发为一体的互联网医院。真正实现“网上就医、网上购药”等在线诊疗服务。
        </div>
        <div>
          &emsp;&emsp;堆云健康依托堆云健康公众号采用“互联网+医疗”的模式，面向全国提供在线咨询、评估、诊疗、护理、保健、科教等服务，通过线上问诊、远程会诊等多种方式，为患者提供方便快捷的服务，同时也为医生提供了更多的患者流量。
        </div>
        <div class="bgimg bgimg21" />
        <h3>项目亮点</h3>
        <div>
          1、堆云健康是一个综合平台，通过互联网服务平台可以为广大患者提供全方位的医疗健康服务，包括在线咨询、预约挂号、在线问诊、药品配送等功能，以及医生资源、科研资源等。<br />2、堆云健康将和其他线上线下医疗机构合作，形成资源共享，优势互补。
        </div>
        <div class="bgimg bgimg22 mobile" />
        <div>
          堆云健康现向全国招募皮肤科医生，该岗位主要负责开展互联网医院日常业务工作，包括但不限于线上咨询、问诊、开药、检查等。
        </div>
        <h3>项目招募条件</h3>
        <div class="bgimg bgimg23" />
        <div>1.熟悉皮肤科常见的临床表现、具备诊断以及治疗的原则和方法。</div>
        <div>2.有一定的工作经验，具有较强的沟通能力和较好的表达能力。</div>
        <div>3.身心健康，具有良好的职业道德。</div>
        <div>4.遵守国家法律法规、执业医师法、医疗机构管理条例等。</div>
        <div>5.工作期间，能积极参加医疗机构组织的各项业务培训和活动，严格遵守各项规章制度。</div>
        <div>6.自愿参加医患沟通小组，加强医患沟通与交流，积极推动相关工作。</div>
        <h3>项目报名方式</h3>
        <div>1.报名时间：2023年4月1日至2023年12月31日，招满为止。</div>
        <div>2.报名方式：关注“堆云健康”微信公众号，私信留言。</div>
        <div class="bgimg bgimg0" />
      </div>

      <div class="contentwz" v-if="id === 3">
        <div>
          &emsp;&emsp;堆云健康是一家一站式医学管理方案和服务提供商，聚焦于脱发等皮肤科常见、多发疾病，打通防脱、养护、药物、植发、假发五大板块，旨在为医生提供学术交流、在线诊疗、慢病管理等一系列专业工具；依托互联网医院，通过药品、健康产品、智能设备，联合药店和健康机构等合作伙伴，为用户提供全程管理方案和服务。
        </div>

        <div class="bgimg bgimg31" />
        <div>
          &emsp;&emsp;公司将秉承“以患者为中心”的服务理念，组建一站式·毛发医学·管理中心，充分发挥互联网诊疗优势，为用户建立健康档案，提供线上、线下全方位的检测、评估、诊疗、护理、保健、健康管理、科普教育等服务，打造标准化服务路径，通过改善毛发生长环境、调节毛发毛囊健康状况、配合口服+外用药物治疗、AI智能设备监测等方式，达到维护用户毛发健康的目的。
        </div>
        <div class="bgimg bgimg32 mobile" />
        <div>
          堆云健康下属的互联网医院小程序已经完成产品上线，主要功能有：<br />1、在线咨询：通过图文、语音、视频等简单沟通病情，解决疑问。<br />2、在线问诊：通过图文、语音、视频等方式在线问诊，医生给出诊疗意见。<br />3、复诊购药：医生在线开具处方，患者下单购买药品，平台快递到家。<br />4、在线随访：医生通过随访功能了解患者病情进展。<br />5、知识科普：医生发布科普患教文章、视频等，帮助患者了解疾病知识。<br />
        </div>
        <div class="bgimg bgimg0" />
      </div>
    </div>
  </div>
</template>

<script>
import list from '@/utils/data.js'

export default {
  name: 'NewsDetail',
  data() {
    return {
      data: {},
      id: null
    }
  },
  created() {
    const id = this.$route?.query?.id
    if (id) {
      console.log(parseInt(id))
      this.data = list[parseInt(id) - 1]
      this.id = parseInt(id)
    }
  },
  methods: {
    handleContent(ll) {
      let str = ''
      ll.map((obj, i) => {
        if (obj.type === 'letter') {
          str += `<div data-v-1e0b167e >${obj.content}</div>\n`
        } else if (obj.type === 'img') {
          // str += `<img style="width: 47.342043639069445%; height:auto;" src="url(../../assets/ls/lsbg.png)" alt="">`
          str += `<div data-v-1e0b167e${i} class='bgimg' />`
        }
      })
      console.log(str, 'str')
      return str
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding-top: 73px;
  .content {
    width: 75%;
    margin: auto;
    margin-top: 76px;
    text-align: center;
    .bgimg {
      margin: 48px auto;
      width: 640px;
      height: 427px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .mobile {
      width: 411px;
      height: 745px;
    }
    .bgimg0 {
      width: 750px;
      background-image: url(../../assets/ls/code.png);
    }
    .bgimg11 {
      background-image: url(../../assets/ls/11.png);
    }
    .bgimg12 {
      background-image: url(../../assets/ls/12.png);
    }
    .bgimg13 {
      background-image: url(../../assets/ls/13.png);
    }
    .bgimg21 {
      background-image: url(../../assets/ls/21.png);
    }
    .bgimg22 {
      background-image: url(../../assets/ls/22.png);
    }
    .bgimg23 {
      background-image: url(../../assets/ls/23.png);
    }
    .bgimg31 {
      background-image: url(../../assets/ls/31.png);
    }
    .bgimg32 {
      background-image: url(../../assets/ls/32.png);
    }
    .title {
      width: 966px;
      font-size: 42px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 48px;
      margin: auto;
    }
    .des {
      font-size: 14px;
      font-family: MicrosoftSansSerif;
      color: #999999;
      line-height: 16px;
      margin-top: 24px;
      margin-bottom: 54px;
    }
    .contentimg {
      width: 100%;
      height: 500px;
      margin-bottom: 48px;
    }
    .contentwz {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 32px;
      text-align: left;
      margin-bottom: 128px;
    }
  }
}
</style>

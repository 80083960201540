import imgurl1 from '@/assets/ls/11.png'
import imgurl2 from '@/assets/ls/21.png'
import imgurl3 from '@/assets/ls/31.png'
const data = [
  {
    id: 1,
    title: '堆云健康与国内各大知名医院医生陆续开展合作，提供毛发全产业服务',
    createTime: '2023-03-24 18:07',
    des: '随着互联网医疗技术的发展，越来越多的用户开始选择线上平台进行治疗。其中，在毛发健康领域，堆云健康就是一家一站式毛发医学管理解决方案和服务商，它致力于通过技术创新和优质服务为广大脱发患者提供解决方案。为了更好地服务医生及患者，提高医生的工作效率，解决患者的真正需求，堆云健康已研发上线自己的公众号，并已陆续开展与各大医院医生联手。',
    imgurl: imgurl1
  },
  {
    id: 2,
    title: '堆云健康全国招募皮肤科医生',
    createTime: '2023-03-31 18:30',
    des: '堆云是一家以互联网医疗为核心的移动医疗平台，旨在为用户提供在线问诊、处方、药品配送及健康管理等服务。预计2023年堆云将与国内 1000多位医生合作，为超过40万患者提供优质的医疗服务。堆云依托移动互联网医疗平台，充分利用互联网技术和创新思维，积极探索“医、药、患”全流程就医服务模式。通过技术赋能、资源整合，搭建了集防脱,养护，药物、植发和假发为一体的互联网医院。真正实现“网上就医、网上购药”等在线诊疗服务。堆云健康依托堆云健康公众号采用“互联网+医疗”的模式，面向全国提供在线咨询、评估、诊疗、护理、保健、科教等服务，通过线上问诊、远程会诊等多种方式，为患者提供方便快捷的服务，同时也为医生提供了更多的患者流量。',
    imgurl: imgurl2
  },
  {
    id: 3,
    title: '堆云健康一站式·医学·皮肤健康管理服务平台',
    createTime: '2023-03-16 18:30',
    des: '堆云健康是一家一站式医学管理方案和服务提供商，聚焦于脱发等皮肤科常见、多发疾病，打通防脱、养护、药物、植发、假发五大板块，旨在为医生提供学术交流、在线诊疗、慢病管理等一系列专业工具；依托互联网医院，通过药品、健康产品、智能设备，联合药店和健康机构等合作伙伴，为用户提供全程管理方案和服务。',
    imgurl: imgurl3
  }
]
export default data
